<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<mat-drawer-container [hasBackdrop]="false">
  <mat-drawer #drawer mode="over">
    <article class="drawer">
      <section class="header">
        <ng-content select="[drawerHeaderContent]"></ng-content>
      </section>
      <section class="information">
        <div class="body">
          <ng-content select="[drawerBodyContent]"></ng-content>
        </div>
        <div class="horizontal-divider"></div>
        <div class="verto-info">
          <ng-content select="[drawerFooterContent]"></ng-content>
        </div>
      </section>
    </article>
  </mat-drawer>

  <article class="box-container">
    <section class="box-wrapper">
      <section class="header">
        <ng-content select="[headerContent]"></ng-content>
      </section>
      <engage-progressbar
        *ngIf="progressBarEnabled"
        [page]="page"
        [totalPages]="totalPages"
        [barColor]="'#00b2e3'"
        [backgroundColor]="'#6a6b6c'"
        [blockColor]="'#131415'"
        [progressBarHeight]="8"
      >
      </engage-progressbar>
      <section class="body">
        <ng-content select="[bodyContent]"></ng-content>
      </section>
      <section #footer class="footer">
        <ng-content select="[footerContent]"></ng-content>
      </section>
    </section>
  </article>
</mat-drawer-container>

<button *ngIf="!disableDrawerButton" class="open-drawer" type="button" (click)="drawer.toggle()">
  <div>
    <span class="hamburger bun" [class.enabled]="drawer.opened"></span>
    <span class="hamburger patty" [class.enabled]="drawer.opened"></span>
    <span class="hamburger bun" [class.enabled]="drawer.opened"></span>
  </div>
</button>
