/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Directive, ElementRef, Input, Inject, OnInit } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

// Configs
import { EngageUtilsConfig } from './engage-utils-config';

@Directive({
  selector: '[engage-asset-href]',
})
export class EngageHrefDirective implements OnInit {
  @Input() path: string;

  constructor(
    private elRef: ElementRef,
    private engageSrcConfig: EngageUtilsConfig,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {}

  ngOnInit(): void {
    if (this.engageSrcConfig.assetsPath) {
      this.elRef.nativeElement.href = `assets/${this.engageSrcConfig.assetsPath}/${this._srcPath}`;
    }
  }

  private get _srcPath(): string {
    return this.elRef.nativeElement.href.replace(window.location.origin + this.baseHref, '');
  }
}
